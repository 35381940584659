import React from 'react';

const ContactArea = () => {
    return (
        <>
            <section className="contact-area pt-120 pb-90" data-background="assets/img/bg/bg-map.png">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-envelope"></i>
                                <h3>Mail Here</h3>
                                <p>Admin@rsb.com.bd</p>
                                <p>Info@rsb.com.bd</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-map-marker-alt"></i>
                                <h3>Visit Here</h3>
                                <p>32/35, Probal Housing,Ring Road, Adabor,
                                    Dhaka - 1216, Bangladesh</p>
                            </div>
                        </div>
                        <div className="col-xl-4  col-lg-4 col-md-4 ">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-phone"></i>
                                <h3>Call Here</h3>
                                <p>Reporting Hotline: 01406195625, 01304457928, 01928548462</p>
                                <p>Software Support: 01713391267, 01713391268</p>
                                <p>Accounts Manager: 01760096172</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactArea;