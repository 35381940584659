import React from 'react';

const HomeAboutArea = () => {
   return (
      <>
         <section className="about-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5 about_left">
                     <div className="medical-icon-brand-2">
                        <img src="img/about/medical-brand-icon-border.png" alt=""/>
                     </div>
                     <div className="about-left-side pos-rel mb-30">
                        <div className="about-front-img">
                           <img src="img/about/about-img.jpg" alt=""/>
                        </div>
                        <div className="about-shape">
                           <img src="img/about/about-shape.png" alt=""/>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h5>About Us</h5>
                           <h1>Short Story About Radiological Solution Bangladesh.</h1>
                        </div>
                        <div className="about-text">
                           <p>Radiological Solution Bangladesh is the market leader in Online reporting systems (X-ray, CT scan, MRI, Mammography, ECG, and so on) in Bangladesh. Emergency Care, Diagnostic Centers, Physicians, Stand-Alone Hospitals, and Groups of Hospitals - large and small - are all served by this Platform.</p>
                           <p>Radiological Solution Bangladesh ​leads the industry as a proven PACS and RIS/PACS system provider with commendable customization and scalability. Radiological Solution Bangladesh is committed to offering the best possible customer service and support, resulting in the least amount of downtime possible. As a result, clients/facilities benefit from the highest possible return on investment, while patients benefit from inexpensive, efficient, and high-quality treatment.</p><br/>
                        </div>
                        <div className="about-author d-flex align-items-center">
                           <div className="author-ava">
                              <img src="img/about/author-ava.png" alt=""/>
                           </div>
                           <div className="author-desination">
                              <h4>Fayez Ahmed</h4>
                              <h6>Chief Technology Officer</h6>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAboutArea;