import React from 'react';

const ContactMap = () => {
    return (
        <>
            <section className="map-area">

                    <div style={{ width: '100%' }}>
                        <iframe title="Contact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d116779.07034344629!2d90.24223812959526!3d23.841846222325316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c11350cc7651%3A0x7c30c02cb1736b27!2sRadiological%20Solution%20Bangladesh!5e0!3m2!1sen!2sbd!4v1639969991473!5m2!1sen!2sbd" style={{ width: '100%' }} height={600} allowFullScreen="" loading="lazy"></iframe>
                    </div>

            </section>
        </>
    );
};

export default ContactMap;