const firebaseConfig = {
    apiKey: "AIzaSyCh7AwVIa09SMuyB1XmuteC8EOssKODXAQ",
    authDomain: "rsb-web-4fc52.firebaseapp.com",
    projectId: "rsb-web-4fc52",
    storageBucket: "rsb-web-4fc52.appspot.com",
    messagingSenderId: "431017971129",
    appId: "1:431017971129:web:b5379521f1aa7fd77f3a68",
    measurementId: "G-5VC03N16L4"
};

export default firebaseConfig;




  