import React from 'react';

const CommonSectionArea = ({area_header}) => {
   return (
      <>
         <section className="about-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5">
                     {area_header}
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h5>About Us</h5>
                           <h1>Short Story About RSB.</h1>
                        </div>
                        <div className="about-text mb-50">
                           <p>Radiological Solution Bangladesh is the market leader in Online reporting systems (X-ray, CT scan, MRI, Mammography, ECG, and so on) in Bangladesh. Emergency Care, Diagnostic Centers, Physicians, Stand-Alone Hospitals, and Groups of Hospitals - large and small - are all served by this Platform.</p>
                        </div>
                        <div className="our-destination">
                           <div className="single-item mb-30">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-1.png" alt=""/>
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Mission</h3>
                                 <p>Providing better teleradiology service.</p>
                              </div>
                           </div>
                           <div className="single-item">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-2.png" alt=""/>
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Vission</h3>
                                 <p>Reliable Radiological Reporting in a Short Time.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonSectionArea;